<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-2-1banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">智慧医疗数字化</div>
            <div class="head-explainBox-content1">对医疗数据进行整合和分析，提高医疗服务的数据质量和效率</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">对医疗数据进行整合和分析，提高医疗服务的数据质量和效率</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">实施方案</span></div>
            <div class="head-list-item"><span class="title">方实施收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
            </div>
            <div class="purchase-content-right">
                <div class="selected-box">
                    <div class="selected-card">
                        <div class="title1">  各部门有大量纸质、Excel报表需求，存在大量<br/>
                        人工抄写、录入及计算，时效性和准确性较差
                        </div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">  靠人工对企业经营数据进行统计分析，<br/>已经无法满足公司的精细化管理要求</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 25rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-1更好的体验.svg"/>
                        <div>
                            <div class="title2">使最终用户拥有更好的体验 </div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-15GF报告.svg"/>
                        <div>
                            <div class="title2">建立自动流程以生成5GF报告数据</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-1数据分析.svg"/>
                        <div>
                            <div class="title2">节省分析师的时间，使其专注于数据分析</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">实施收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                    <div class="card2">
                        <img class="logo2" src="../../assets/image/selected/03-2-1数据源.svg"/>
                        <div class="contain2">
                            <div style="display:flex;margin:auto">
                               <div class="title2-1">30</div>
                                <div class="title2-2">个数据源</div>
                            </div>

                        </div>
                    </div>
                    <div class="card2">
                        <img class="logo2" src="../../assets/image/selected/03-2-1KPI.svg"/>
                        <div class="contain2">
                            <div style="display:flex;margin:auto">
                               <div class="title2-1">20+</div>
                                <div class="title2-2">核心KPI</div>
                            </div>

                        </div>
                    </div><div class="card2">
                        <img class="logo2" src="../../assets/image/selected/03-2-1人工成本.svg"/>
                        <div class="contain2">
                            <div style="display:flex;margin:auto">
                                <div class="title2-2">高度节约人工成本</div>
                            </div>

                        </div>
                    </div><div class="card2">
                        <img class="logo2" src="../../assets/image/selected/03-2-1缩短报表时间.svg"/>
                        <div class="contain2">
                            <div style="display:flex;margin:auto">
                                <div class="title2-2">缩短报表制作时间</div>
                            </div>

                        </div>
                    </div><div class="card2">
                        <img class="logo2" src="../../assets/image/selected/03-2-1自动处理.svg"/>
                        <div class="contain2">
                            <div style="display:flex;margin:auto">
                                <div class="title2-2">自动化处理数据</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.special-style{
    height: 5rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #333333;
    line-height: 3rem;
    display: flex;
    .title-6{
        text-align: center;
        margin: auto;
        width: 5rem;
        height: 5rem;
        font-size: 4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #ED5847;
        line-height: 3rem;
        margin-top: -0.5rem;
    }
}
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-doit{
    color: #612177;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 107.2rem;
    height: 62.1rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 20rem;
    display: flex;
    .selected-card{
        width: 53.5rem;
        height: 15rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: center;
            width: 47.1rem;
            height: 6rem;
            font-size: 16px;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #005395;
    }
}
.purchase-box{
    width: 106rem;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    .card{
        width: 34.8rem;
        height: 12rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 3.3rem 0 0 1.2rem;
        }
        .title2{
            width: 25.2rem;
            height: 4.8rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 2.4rem;

            margin: 3.7rem 0 0 1.2rem;
        }
    }
    .card2{
        width: 20rem;
        height: 21rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        .logo2{
            margin: 4rem 0 0 6.7rem;
            width: 6.6rem;
            height: 6.6rem;
        }
        .contain2{
            display: flex;
            text-align: center;
            width: 13.8rem;
            height: 2.6rem;
            margin: auto;
            margin-top: 3rem;
            .title2-1{
                font-size: 3rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #005395;
                line-height: 2.6rem;

            }
            .title2-2{
                height: 2.2rem;
                font-size: 1.6rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 2.2rem;
                margin-top: 1rem;
            }
        }
    }
}
</style>
